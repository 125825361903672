import React, { useState } from "react";
import { useLocation }from "react-router-dom";
import { addDoc, collection } from "firebase/firestore/lite";
import { db } from "../../../firebase/firebaseConfig";
import { Modal, Box, Card, CardContent, Typography, CardActions, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
function RegistrationForm(props) {
  const [fullName, setFullName] = useState("");
  // const [contact, setContact] = useState("");
  const [pending, setPending] = useState();
  const [ipAddress, setIpAddress] = useState("");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [diet, setDiet] = useState("");
  const [showIcal, setShowIcal] = useState(false);

  let location = 'other';

  const [modalHeader, setModalHeader] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  if (useLocation().pathname === '/yoan') {
    location = 'highlands'
  }

  fetch("//api.ipify.org/")
  .then((res) => res.text())
  .then((ip) => {
    setIpAddress(ip);
  });

  const handleSubmit = () => {
    if (fullName && 
      // contact && !
      !pending){
      setPending(true);

      const dbRef = collection(db, "rsvps");
      // Add a new document in collection "rsvps"
      addDoc(dbRef, { 
        name: fullName, 
        // contact: contact, 
        source: location,
        createdAt: new Date(),
        ipAddress: ipAddress,
        diet: diet,
        children: children,
        adults: adults,
        partyId: props.party.id,

       })
        .then((docRef) => {
          setPending(false);

          setFullName("");
          // setContact("");
          setShowIcal(true);
          setModalHeader('Thanks!');
          setModalDescription('See you at the party!');
          setModalButtonText("Can't wait!");
          handleOpen();
        })
        .catch((error) => {
          setPending(false);

        });
    } else {
      setModalHeader('Whoops!');
      setShowIcal(false);
      // if (!contact) {
      //   setModalDescription(`Just use a junk email if you're worried lol`);
      // }
      if (!fullName) {
        setModalDescription(`We need to know who you are if you're coming!`);
      }
      setModalButtonText('My apologies!');
      handleOpen();
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <span id="target-rsvp"/>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        {props.header}
        </Typography>

        <TextField 
          fullWidth
          margin="normal"
          required
          value={fullName}
          id="name"
          onChange={(e) => setFullName(e.target.value)}
          label="Your Party's Name" variant="outlined" 
          helperText=""
        />

        {/* <TextField 
          fullWidth
          margin="normal"
          value={contact}
          id="contact"
          onChange={(e) => setContact(e.target.value)}
          label="Contact Info" variant="outlined" 
          helperText="Your email or phone number To receive updates about party (weather, changes, etc)"
        /> */}


        <FormControl sx={{ m: 2, minWidth: 120 }}>
        <InputLabel id="adults">Adults</InputLabel>
        <Select
          labelId="adults-options"
          id="demo-simple-select-helper"
          value={adults}
          label="Adults"
          onChange={(e) => setAdults(e.target.value)}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 2, minWidth: 120 }}>
        <InputLabel id="children">Children</InputLabel>
        <Select
          value={children}
          onChange={(e) => setChildren(e.target.value)}
          displayEmpty
          label="Children"
        >
          <MenuItem value={0}>
            <em>None</em>
          </MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </Select>
      </FormControl>


        <TextField 
          fullWidth
          margin="normal"
          value={diet}
          id="diet"
          onChange={(e) => setDiet(e.target.value)}
          label="Any allergies?" variant="outlined" 
        />
        <CardActions style={{justifyContent: 'center'}}>
          <Button disabled={pending} fullWidth variant="contained" onClick={() => handleSubmit()}>
            Count me in!
          </Button>
        </CardActions>
      </CardContent>

      <Modal
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > 
        <Box sx={style} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalHeader}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalDescription}
          </Typography>
          {props.party.icsLink && showIcal?
            <a href={props.party.icsLink} target='_blank' rel="noreferrer">
                <Button sx={{ mt: 2 }} fullWidth variant="outlined"><CalendarMonthOutlinedIcon />Add to my calendar </Button>
            </a>
        :null}
          <Button sx={{ mt: 2 }} fullWidth variant="contained">{modalButtonText}</Button>
        </Box>
      </Modal>
    </Card>
  );
}
export default RegistrationForm;
