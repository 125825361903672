import { useState, useEffect } from "react";
import "./App.css";
import Party from './pages/party'
import { Route }from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore/lite";
import {db} from './firebase/firebaseConfig';
import { Container } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
function App() {

  const [parties, setParties] = useState([]);
  const partyCode = (window.location.pathname+window.location.search).substr(1);
  const fetchParties = async () => {
    const q = query(collection(db, "parties"), where("enabled", "==", true), where("code", "==", partyCode));
    const querySnapshot = await getDocs(q)
    .then((qs)=>{
      const newData = qs.docs
      .map((doc) => ({...doc.data(), id:doc.id }));
      if (newData[0]) {
          newData[0].cards = newData[0].cards.sort(function(a, b){
            return a.order-b.order;
        })
      }
      setParties(newData);
    })
  };
  const theme = createTheme({

  });
  theme.spacing(4);
  useEffect(() => {
    fetchParties();
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <Container>
        <Route>
          <Party parties={parties}></Party>
        </Route>
      </Container>
      </ThemeProvider>
    </div>
  );
}

export default App;
