import React, { useState, useEffect } from "react";
import { addDoc, collection } from "firebase/firestore/lite";
import { db } from "../../../../firebase/firebaseConfig";
import {
  Box,
  Modal,
  InputLabel,
  NativeSelect,
  FormControl,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

function CustomizeRSVP(props) {
  const [train, setTrain] = useState("");
  const [ticketName, setTicketName] = useState("");
  const [character, setCharacter] = useState("");
  const [pending, setPending] = useState();
  const [ipAddress, setIpAddress] = useState("");

  const [modalHeader, setModalHeader] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalButtonText, setModalButtonText] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let location = "other";

  useEffect(() => {
    setTrain("kalamari-desert");
    setCharacter("mario");
  }, []);
  if (useLocation().pathname === "/yoan") {
    location = "highlands";
  }
  fetch("//api.ipify.org/")
  .then((res) => res.text())
  .then((ip) => {
    setIpAddress(ip);
  });
  const config = {
    fields: [
      {
        name: "ticketName",
        type: "text",
      },
      {
        name: "train",
        type: "select",
        options: [
          {
            display: "Kalamari Desert",
            value: "kalamari-desert",
            image: "kalamari-desert.jpg",
          },
          {
            display: "Merry Mountain",
            value: "merry-mountain",
            image: "merry-mountain.png",
          },
          {
            display: "Rainbow Road",
            value: "rainbow-road",
            image: "rainbow-road.png",
          },
          {
            display: "Super Bell Subway",
            value: "super-bell-subway",
            image: "super-bell-subway.png",
          },
          {
            display: "Sydney Sprint",
            value: "sydney-sprint",
            image: "sydney-sprint.png",
          },
          {
            display: "Toad Harbor",
            value: "toad-harbor",
            image: "toad-harbor.png",
          },
        ],
      },
      {
        name: "character",
        type: "select",
        options: [
          {
            display: "Baby Daisy",
            value: "baby_daisy",
            image: "baby_daisy.png",
          },
          {
            display: "Baby Luigi",
            value: "baby_luigi",
            image: "baby_luigi.png",
          },
          {
            display: "Baby Mario",
            value: "baby_mario",
            image: "baby_mario.png",
          },
          {
            display: "Baby Peach",
            value: "baby_peach",
            image: "baby_peach.png",
          },
          {
            display: "Baby Rosalina",
            value: "baby_rosalina",
            image: "baby_rosalina.png",
          },
          {
            display: "Bowser",
            value: "bowser",
            image: "bowser.png",
          },
          {
            display: "Bowser Jr.",
            value: "bowser_jr",
            image: "bowser_jr.png",
          },
          {
            display: "Cat Peach",
            value: "cat_peach",
            image: "cat_peach.png",
          },
          {
            display: "Daisy",
            value: "daisy",
            image: "daisy.png",
          },
          {
            display: "Donkey Kong",
            value: "donkey_kong",
            image: "donkey_kong.png",
          },
          {
            display: "Dry Bones",
            value: "dry_bones",
            image: "dry_bones.png",
          },
          {
            display: "Dry Bowser",
            value: "dry_bowser",
            image: "dry_bowser.png",
          },
          {
            display: "Iggy",
            value: "iggy",
            image: "iggy.png",
          },
          {
            display: "Inkling Female",
            value: "inkling_female",
            image: "inkling_female.png",
          },
          {
            display: "Inklink Male",
            value: "inklink_male",
            image: "inkling_male.png",
          },
          {
            display: "Isabelle",
            value: "isabelle",
            image: "isabelle.png",
          },
          {
            display: "King Boo",
            value: "king_boo",
            image: "king_boo.png",
          },
          {
            display: "Koopa",
            value: "koopa",
            image: "koopa.png",
          },
          {
            display: "Lakitu",
            value: "lakitu",
            image: "lakitu.png",
          },
          {
            display: "Larry",
            value: "larry",
            image: "larry.png",
          },
          {
            display: "Lemmy",
            value: "lemmy",
            image: "lemmy.png",
          },
          {
            display: "Link",
            value: "link",
            image: "link.png",
          },
          {
            display: "Ludwig",
            value: "ludwig",
            image: "ludwig.png",
          },
          {
            display: "Luigi",
            value: "luigi",
            image: "luigi.png",
          },
          {
            display: "Mario",
            value: "mario",
            image: "mario.png",
          },
          {
            display: "Metal Mario",
            value: "metal_mario",
            image: "metal_mario.png",
          },
          {
            display: "Morton",
            value: "morton",
            image: "morton.png",
          },
          {
            display: "Peach",
            value: "peach",
            image: "peach.png",
          },
          {
            display: "Pink Gold Peach",
            value: "pink_gold_peach",
            image: "pink_gold_peach.png",
          },
          {
            display: "Rosalina",
            value: "rosalina",
            image: "rosalina.png",
          },
          {
            display: "Roy",
            value: "roy",
            image: "roy.png",
          },
          {
            display: "Shy Guy",
            value: "shy_guy",
            image: "shy_guy.png",
          },
          {
            display: "Tanooki Mario",
            value: "tanooki_mario",
            image: "tanooki_mario.png",
          },
          {
            display: "Toad",
            value: "toad",
            image: "toad.png",
          },
          {
            display: "Toadette",
            value: "toadette",
            image: "toadette.png",
          },
          {
            display: "Villager Male",
            value: "villager_male",
            image: "villager_male.png",
          },
          {
            display: "Villager Female",
            value: "villager_female",
            image: "villager_female.png",
          },
          {
            display: "Waluigi",
            value: "waluigi",
            image: "waluigi.png",
          },
          {
            display: "Wario",
            value: "wario",
            image: "wario.png",
          },
          {
            display: "Wendy",
            value: "wendy",
            image: "wendy.png",
          },
          {
            display: "Yoshi",
            value: "yoshi",
            image: "yoshi.png",
          },
        ],
      },
    ],
  };

  const getImage = (options) => {

    let fieldInfo = config.fields.filter(
      (field) => field.name === options.field
    );

    if (fieldInfo && fieldInfo[0]) {
      let image = fieldInfo[0].options.filter(
        (item) => item.value === options.value
      );
      if (image && image[0]) {
        return image[0].image;
      }
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleSubmit = () => {
    const dbRef = collection(db, "tickets");
    if (ticketName && !pending) {
      setPending(true);
      // Add a new document in collection "rsvps"
      addDoc(dbRef, {
        name: ticketName,
        train: train,
        character: character,
        source: location,
        createdAt: new Date(),
        ipAddress: ipAddress,
      })
        .then((docRef) => {
          setPending(false);
          setTicketName("");
          setModalHeader("Thanks!");
          setModalDescription(
            "You will get your customized ticket at the party!"
          );
          setModalButtonText("Great!");
          handleOpen();
        })
        .catch((error) => {
          setPending(false);
        });
    } else {
      setModalHeader("Whoops!");
      setModalDescription(
        "We need your name so we can give you your ticket at the party!"
      );
      setModalButtonText("Oh yeah!");
      handleOpen();
    }
  };
  return (
    <Card sx={{ mt: 2 }}>
      <span id="target-customize"></span>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Create Customized Tickets For Each Member Of Your Partay
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <ReactMarkdown
            children={props.info}
            rehypePlugins={[rehypeRaw]}
          ></ReactMarkdown>
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          required
          value={ticketName}
          id="ticketName"
          onChange={(e) => setTicketName(e.target.value)}
          label="Who is this ticket for?"
          variant="outlined"
          helperText="Name will not be on the ticket. Only used to match you with your ticket."
        />

        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Favorite Train from Mario Kart?
            </InputLabel>
            <NativeSelect
              onChange={(e) => setTrain(e.target.value)}
              value={train}
              s
              inputProps={{
                name: "train",
                id: "train",
              }}
            >
              {config.fields[1].options.map((option, i) => {
                return (
                  <option value={option.value} key={option.value}>
                    {option.display}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Box>
        <img
          width="250px"
          height="auto"
          src={
            "/images/mariokart/" +
            getImage({
              field: "train",
              value: train,
              default: "kalamari-desert",
            })
          }
          alt={train}
        />
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Favorite Character from Mario Kart?
            </InputLabel>
            <NativeSelect
              onChange={(e) => setCharacter(e.target.value)}
              value={character}
              inputProps={{
                name: "character",
                id: "character",
              }}
            >
              {config.fields[2].options.map((option, i) => {
                return (
                  <option value={option.value} key={option.value}>
                    {option.display}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Box>
        <img
          width="250px"
          height="auto"
          src={
            "/images/mariokart/characters/" +
            getImage({ field: "character", value: character })
          }
          alt={character}
        />
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            disabled={pending}
            fullWidth
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Create {ticketName ? ticketName + "'s" : ""} ticket
          </Button>
        </CardActions>
      </CardContent>

      <Modal
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalHeader}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalDescription}
          </Typography>
          <Button sx={{ mt: 2 }} fullWidth variant="contained">
            {modalButtonText}
          </Button>
        </Box>
      </Modal>
    </Card>
  );
}
export default CustomizeRSVP;
