import './Heading.css';
import { Card, CardContent, CardActions, Button, Stack, Typography } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import { ScrollTo } from "react-scroll-to";

function Heading(props) {

    const getElementYPosition = (id) => {
        let target = document.getElementById(id);
        if (target) {
            var rect = target.getBoundingClientRect();
            return rect.top
        }
    };
    let hasRsvp = false;
    let hasTicket = false;
    if (props.cards) {
     props.cards.forEach(c => {
       if (c.type === 'form') {
         hasRsvp = true;
       }
         if (c.type === 'customizeTicket') {
           hasTicket = true;
         }
    });
    }

    return (
    <Card>
        <CardContent>

        {props.image ? <div className="heading__image-container">
            <img className="heading__image-image" src={props.image} width="100%" alt={props.image}></img>
        </div>
        : null}
        <div className="heading__data">
        <Typography variant="h4">{props.name}</Typography>

        <Typography variant="h5">{props.startTime.toDate().toLocaleDateString('en-US', {
              month : 'long',
              day : 'numeric',
              year : 'numeric'
          })}
        </Typography>
        <div>{props.startTime.toDate().toLocaleString('en-US', {
            hour : 'numeric', minute: 'numeric'
        })} - {props.endTime.toDate().toLocaleString('en-US', {
            hour : 'numeric', minute: 'numeric'
        })}</div>
        </div>
        
        <CardActions style={{justifyContent: 'center'}}>
    <Stack spacing={1} direction="row" >
        {hasRsvp ?
        <ScrollTo>
        {({ scroll }) => (
            <Button onClick={ () => scroll({ y: getElementYPosition('target-rsvp'), smooth: true })} variant="contained" startIcon={<EmojiEmotionsOutlinedIcon />}>Click here to RSVP</Button>
        )}
      </ScrollTo>
      : null}

    {props.ics?
        <a href={props.ics} target='_blank' rel="noreferrer">
            <Button variant="contained"><CalendarMonthOutlinedIcon /></Button>
        </a>
    :null}
      {hasTicket ?
      <ScrollTo>
        {({ scroll }) => (
            <Button onClick={ () => scroll({ y: getElementYPosition('target-customize'), smooth: true })} variant="contained" startIcon={<LocalActivityOutlinedIcon/>}>My Ticket</Button>
        )}
      </ScrollTo>
      : null}
    </Stack>
        </CardActions>
        <Typography variant="h6">{props.subtitle}</Typography>

    </CardContent>
    </Card>
    )
}

export default Heading;
