import { Info, Heading, Map } from "../components/Cards";
import RegistrationForm from "../components/Cards/Form";
import TicketCustomize from "../components/Cards/Form/TicketCustomize";
import ImageListCard from "../components/Cards/ImageListCard";
import { Box } from '@mui/system';
const PartyPage = (props) =>
{
  let parties = props.parties;
  return parties.map((party) => {
    return (
      <Box key={party.name}>
        <Heading
        name={party.name}
        subtitle={party.subtitle}
        image={party.image}
        key={party.id}
        startTime={party.startTime}
        endTime={party.endTime}
        cards={party.cards}
        ics={party.icsLink}
        ></Heading>
        <Map
        location={party.location}
        ></Map>
      {
        party.cards.map((card, i) => {
          let renderedCard = "";
          switch (card.type) {
            case 'form' :
              renderedCard =
                <RegistrationForm
                key={i}
                header={card.header}
                fields={card.fields}
                party={party}
                ></RegistrationForm>
              break;
            case 'customizeTicket' :
              renderedCard =
                <TicketCustomize
                key={i}
                header={card.header}
                fields={card.fields}
                info={card.info}
                ></TicketCustomize>
              break;
            case 'imagelist':
              renderedCard = 
                <ImageListCard
                key={i}
                header={card.header}
                fields={card.fields}
                info={card.info}
                list={card.list ? card.list : []}
                footer={card.footer}
                >
                </ImageListCard>
              break;
            case 'info':
            default:
              renderedCard =
                <Info
                key={i}
                header={card.header}
                info={card.info}
                list={card.list ? card.list : []}
                ></Info>
              break;
          }
          return renderedCard
        })
      }
      </Box>
    )
  })
}

export default PartyPage;
