// import './Info.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import { List, ListItemText, ListItem, Divider, Card, CardContent, Typography} from '@mui/material';

function CardInfo(props) {
    return (
      <Card sx={{ mt: 2 }}>
        {props.icon ? <div className="card__icon">
            <img className="card__icon-image" src={'/icons/'+props.icon} alt={props.icon}></img>
        </div>
        : null}
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            {props.header}
            </Typography>

            <Typography variant="body2" color="text.secondary">
            <ReactMarkdown children={props.info} rehypePlugins={[rehypeRaw]}></ReactMarkdown>
            </Typography>
            {
              props.list.map((list,i) => {
                return(
                  <CardContent>
                    <List
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        padding: 0,
                        margin: 0
                      }}
                    >
                      <ListItem 
                        sx={{
                        padding: 0,
                        margin: 0
                        }}>
                        <ListItemText
                          sx={{
                            padding: 0,
                            margin: 0
                            }}
                        primary={list.name} secondary={<ReactMarkdown children={list.details} rehypePlugins={[rehypeRaw]}></ReactMarkdown>} />
                      </ListItem>
                      <Divider 
                        sx={{
                        padding: 0,
                        margin: 0
                        }} variant="inset" component="li" />
                    </List>
                  </CardContent>
                )
            })
          }
        </CardContent>
      </Card>
    )
}

export default CardInfo;