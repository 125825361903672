// import './Info.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import { Card, CardContent, Typography, ImageListItem, ImageList } from '@mui/material';

function ImageListCard(props) {
    return (
      <Card sx={{ mt: 2 }}>
        {props.icon ? <div className="card__icon">
            <img className="card__icon-image" src={'/icons/'+props.icon} alt={props.icon}></img>
        </div>
        : null}
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            {props.header}
            </Typography>

            <Typography variant="body2" color="text.secondary">
            <ReactMarkdown children={props.info} rehypePlugins={[rehypeRaw]}></ReactMarkdown>
            </Typography>

            { props.list && props.list.length > 0 ? 
            <ImageList  cols={2}>
            {
              props.list.map((item,i) => {
                return(


                <ImageListItem key={item.img}>
                <img
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    alt={item.name}
                    loading="lazy"
                />
                </ImageListItem>
                )
            })
          }

</ImageList>
            : ''}
            

            <Typography variant="body2" color="text.secondary">
            <ReactMarkdown children={props.footer} rehypePlugins={[rehypeRaw]}></ReactMarkdown>
            </Typography>

        </CardContent>
      </Card>
    )
}

export default ImageListCard;