import './Map.css';
import What3Words from '../../what3words';
import GPS from '../../GPS';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';

function MapCard(props) {
    return (
    <Card  sx={{ mt: 2 }}>
        <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        {props.location.name}
            </Typography>
            <Grid container >
  <Grid xs={12} md={12}>
            <Box sx={{ m: 2 }}>
                <GPS location={props.location}/>
            </Box>
  </Grid>
  <Grid xs={12} md={4}>

  <Box sx={{ m: 2 }}>

{props.location.w3w?
        <What3Words label={props.location.w3w} image={props.location.w3wImage}></What3Words>
        : null
    }
</Box>
  </Grid>
  </Grid>

        </CardContent>
    </Card>
    )
}

export default MapCard;
