import './What3Words.css';
import { Card, CardContent, Typography,} from '@mui/material';

function What3Words(props) {
    return (
        
        <Card>
        <CardContent>
        <Typography gutterBottom variant="h5" component="div">
            <div className="what3words__set">
                <a href={"https://what3words.com/"+props.label}>
                    <div className="what3words__logo">///</div>
                </a>
                <div className="what3words__words">{props.label}</div>
            </div>
        </Typography>
            <a href={"https://what3words.com/"+props.label}>
                <div className="what3words__image">
                    <img className="what3words__image-image" src={'/images/'+props.image} alt={props.image}></img>
                </div>
            </a>
            <div className="what3words__instructions">This <strong>what3words</strong> address refers to a 3 metre square location. Tap the image or enter the 3 words into the free what3words app to find it.</div>
</CardContent>
        </Card>

    )
}

export default What3Words;